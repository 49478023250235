
import { defineComponent, ref, onMounted, watch } from "vue";
import countries from "@/common/countries";
import { loadStripe } from "@stripe/stripe-js/pure";
import Stripe from "@stripe/stripe-js";
import { convertMoney } from "@/common/helper";
import { apiGetUserInfo } from "@/api/user";
import {
  apiCreateInvoiceUseCredits,
  apiGetVATValueBYCountryCode,
  apiValidateVATNumber,
} from "@/api/invoices";
import { STRIPY_KEY } from "@/common/config";
import { DollarOutlined } from "@ant-design/icons-vue";
import { debounce } from "lodash";

export default defineComponent({
  name: "InvoiceCreate",
  components: { DollarOutlined },
  props: {
    recommend: {
      type: Number,
    },
  },
  setup(props) {
    const amount = ref(5);
    const isLoading = ref(true);
    const total = ref(0);
    const isLoadingButton = ref(false);
    const isDisabledButton = ref(true);
    const validNumber = ref(false);
    const vat = ref(0);
    const vatNumber = ref("");
    const vatNumberStatus = ref("");
    const name = ref("");
    const country = ref("");
    let stripe: Stripe.Stripe | null = null;
    const userCountry = ref("");

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 12 },
      wrapperColAmount: { span: 5 },
    };

    const goToCheckout = async (sessionId: string) => {
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId,
        });
      }
    };

    const createInvoice = async () => {
      isDisabledButton.value = true;
      isLoadingButton.value = true;
      const { data } = await apiCreateInvoiceUseCredits({
        name: name.value,
        country: userCountry.value,
        amount: amount.value,
        vatNumber: vatNumber.value,
      });
      await goToCheckout(data.sessionId);
    };

    const calcTotal = () => {
      let calcVat = vat.value;
      if (validNumber.value) {
        calcVat = 0;
      }
      total.value = Number((amount.value * (1 + calcVat / 100)).toFixed(2));
    };

    const isReady = () => {
      isDisabledButton.value = !(name.value !== "" && userCountry.value !== "" && amount.value > 0);
    };

    const checkVatNumber = async () => {
      if (vatNumber.value !== "") {
        vatNumberStatus.value = "validating";
        const { data } = await apiValidateVATNumber(vatNumber.value);
        validNumber.value = data.valid;
        vatNumberStatus.value = validNumber.value ? "success" : "error";
      } else {
        validNumber.value = false;
      }
      calcTotal();
    };

    const checkVatCountryCode = async (code: string) => {
      if (code !== "") {
        const { data } = await apiGetVATValueBYCountryCode(code);
        vat.value = data.rate;
      }
      calcTotal();
    };

    const handleChange = (value: string) => {
      checkVatCountryCode(value);
      isReady();
    };

    const getUserInfo = async () => {
      const { data } = await apiGetUserInfo();
      name.value = data.name ? data.name : "";
      vatNumber.value = data.vatNumber ? data.vatNumber : "";
      userCountry.value = data.country ? data.country : "";
      handleChange(userCountry.value);
    };

    type OptionsChildren = {
      children: string;
    };

    type Options = {
      children: OptionsChildren[];
    };

    const filterOption = (input: string, option: Options) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const debouncedFunc = debounce(checkVatNumber, 500);

    watch(vatNumber, () => {
      if (vatNumber.value !== "") {
        debouncedFunc();
        vatNumberStatus.value = validNumber.value ? "success" : "error";
      } else {
        vatNumberStatus.value = "";
        validNumber.value = false;
        calcTotal();
      }
      isReady();
    });

    watch(amount, (val: number | string) => {
      let newVal = val;
      if (typeof val === "string") {
        newVal = val.replace(/[^.\d]+/, "");
      }
      amount.value = Number(newVal);
      calcTotal();
      isReady();
    });

    watch(name, () => {
      isReady();
    });

    const load = async () => {
      if (typeof props.recommend !== "undefined") {
        amount.value = props.recommend;
      }
      if (typeof userCountry.value !== "undefined" && userCountry.value !== "") {
        handleChange(userCountry.value);
      } else {
        calcTotal();
      }
      await getUserInfo();
      if (typeof STRIPY_KEY !== "undefined") {
        stripe = await loadStripe(STRIPY_KEY);
        isLoading.value = false;
      }
    };

    onMounted(load);

    const converter = convertMoney;

    return {
      converter,
      amount,
      isLoading,
      total,
      isLoadingButton,
      isDisabledButton,
      validNumber,
      vat,
      vatNumber,
      name,
      country,
      countries,
      userCountry,
      formItemLayout,
      createInvoice,
      filterOption,
      vatNumberStatus,
      checkVatNumber,
      handleChange,
    };
  },
});
